.podcast-signup-page {
    .header {
        padding: 100px 90px;
        h1 {
            color: white;
            font-size: 48px;
            line-height: 60px;
            text-align: left;
            margin: 0 auto;
            width: fit-content;
        }
    }

    .content {
        display: flex;
        justify-content: center;
        align-items: stretch;

        .inner-content {
            padding: 141px 35px 180px 35px;
            text-align: left;

            h4 {
                margin: 0;
                font-size: 30px;
                line-height: 48px;
                margin-bottom: 50px;
            }

            p {
                font-size: 24px;
                line-height: 36px;
                margin: 0;
                margin-bottom: 40px;
            }

            .end-button {
                display: grid;
                place-content: center;
            }
        }

        img {
            display: block;
            width: 50%;
            object-fit: cover;
            object-position: -110px center;
        }
    }
}

@media only screen and (max-width: 1350px) {
    .podcast-signup-page .header h1 {
        font-size: 38px;
        line-height: 48px;
    }

    .podcast-signup-page .header {
        padding: 60px 40px;
    }

    .podcast-signup-page .content .inner-content {
        padding: 60px 30px 100px 30px;
    }
}

@media only screen and (max-width: 1100px) {
    .podcast-signup-page .content .inner-content h4 {
        font-size: 26px;
        line-height: 36px;
        margin-bottom: 35px;
    }

    .podcast-signup-page .content .inner-content p {
        font-size: 18px;
        line-height: 28px;
        margin: 0;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .podcast-signup-page .header h1 br {
        display: none;
    }
    .podcast-signup-page .content {
        flex-direction: column-reverse;
        & > img {
            width: 100%;
            object-position: center center;
        }
    }
}