.header {
    & > img {
        max-height: 211px;
        object-fit: cover;
        object-position: center;
    }
    z-index: 1;
    background: #db7c33;
}

.bkg-blue {
    background: #00344c;
}

.site-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 65px;
    padding-bottom: 48px;
    background-color: white;
    font-size: 25px;

    div.menu-item {
        display: block;
        margin: 0px 3%;

        a {
            text-decoration: none;
            color: #00344c;
        }

        &.home-uppercase {
            text-transform: capitalize;
            color: #d34d00;
        }

        &.join-podcast {
            color: #d34d00;
            font-weight: bold;
        }

        .chevron {
            width: 18px;
            margin-left: 10px;
            cursor: pointer;
        }

        &.with-submenu {
            position: relative;
            

            .submenu-wrapper {
                display: none;
                position: absolute;
                z-index: 1;
                left: 0;
                top: 100%;
                padding-top: 25px;
                
                & > div {
                    background: white;
                    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
                }
                span {
                    display: block;
                    margin-bottom: 10px;
                
                    &:last-child {
                        margin: 0;
                    }
                }

                a {
                    display: block;
                    text-align: left;
                    padding: 1.25rem 1rem;
                    padding-top: 0.75rem;
                    font-weight: bold;
                    color: #222222;
                }
            }

            &:hover .submenu-wrapper {
                display: block;
                width: max-content;
                max-width: 250px;

                a:hover {
                    text-decoration: underline;
                    
                }
            }
        }
    }
}

.site-menu div.menu-item.with-submenu a:hover {
    position: relative;
    cursor: default;
}

.site-menu div.menu-item.with-submenu .submenu-wrapper a:hover {
    cursor: pointer;
}

.mobile-site-menu {
    width: 100%;
    height: auto;
    padding: 20px 0px;
    display: none;
    

    & > div.menu-content > * {
        display: none;
    }

    .mobile-menu-toggler {
        width: fit-content;
        margin: auto;
        margin-right: 20px;
        img {
            width: 30px;
            height: auto;
        }
    }

    &.active {
        & > div.menu-content > * {
            display: block;
        }
    }

    .menu-item { 
        margin: 20px 0px;
        a {
            display: block;
            color: black;
            text-decoration: none;
            font-weight: bold;
        }

        &.with-submenu {
            .submenu-wrapper {
                a {
                    font-weight: normal;
                    display: block;
                    margin: 20px 0px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1350px) {
    .site-menu {
        padding: 30px 0px;
        font-size: 22px;

    }

    .header > img {
        width: 100%;
    }
}
@media only screen and (max-width: 1200px) {

    .site-menu {
        padding: 30px 0px;
        font-size: 20px;
    }
}

@media only screen and (max-width: 1100px) {
    .site-menu {
        padding: 30px 0px;
        font-size: 18px;
    }
}


@media only screen and (max-width: 912px) {

    .site-menu {
        padding: 30px 0px;
    }
}

@media only screen and (max-width: 768px) {

    .site-menu {
        display: none;
    }

    .mobile-site-menu {
        display: block;
    }
}