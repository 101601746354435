.footer-wrapper {
    padding: 90px 75px 80px 75px;
    border-top: 1px solid #cccccc;

    display: flex;

    .left-col {
        text-align: left;
    }

    .footnote {
        margin: 2rem 0 1rem 0;
        width: 298px;
        font-size: 16px;
        line-height: 24px;
        color: #666666;
        font-family: 'Roboto', sans-serif;
    }

    .copyright  {
        font-size: 16px;

        & > span:first-child {
            color: #666666;
        }
    }

    .social-icons {
        margin-left: 20px;
        a {
            margin-right: 5px;

            &.facebook {
                color:  #1877f2;
            }

            &.twitter {
                color: #1da1f2;
            }

            &.youtube {
                color: #ff0000;
            }
        }
    }

    .subtext {
        margin-top: 3rem;
        font-size: 14px;
        color: #919191;
    }

    .equal-housing {
        display: block;
        margin-top: 30px;
        max-width: 160px;
        height: auto;
    }

    .right-col {
        margin-left: 150px;
        #menu-footer {
            padding-left: 0;
            display: grid;
            grid-template-columns: repeat(2, min-content);
            column-gap: 100px;
            list-style: none;
            text-align: left;
            font-family: 'Roboto', sans-serif;
            color: #222222;

            li, ul {
                list-style: none;
                padding-inline-start: 0px;

                &.menu-item {
                    margin-bottom: 1rem;
                }

                a {
                    font-size: 15px;
                    font-weight: 400;
                    color: #222222;
                    text-decoration: none;
                    white-space: nowrap;
                    
                    &.title {
                        display: block;
                        margin-bottom: 1rem;
                        font-weight: 600;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1350px) {
    .footer-wrapper .footnote {
        width: 100%;
    }

    .footer-wrapper .left-col {
        margin-right: 30px;
    }

    .footer-wrapper .right-col {
        margin-left: auto;
        #menu-footer {
            column-gap: 50px;
        }
    }
}

@media only screen and (max-width: 920px) {
    .footer-wrapper .right-col #menu-footer {
        grid-template-columns: repeat(1, min-content);
    }
}

@media only screen and (max-width: 768px) {
    .footer-wrapper .right-col #menu-footer {
        grid-template-columns: repeat(1, min-content);
    }
}

@media only screen and (max-width: 650px) {
    .footer-wrapper {
        display: flex;
        flex-direction: column-reverse;
        padding: 50px 30px 55px 30px;

        .right-col {
            margin-left: unset;
            #menu-footer {
                grid-template-columns: repeat(2, min-content);
            }
        }
    }
}

@media only screen and (max-width: 570px) {
    .footer-wrapper .right-col #menu-footer {
        grid-template-columns: repeat(1, min-content);
    }
}

@media only screen and (max-width: 450px) {
    .footer-wrapper .right-col #menu-footer {
        grid-template-columns: repeat(1, auto);
    }
    
    .footer-wrapper .right-col #menu-footer li a, .footer-wrapper .right-col #menu-footer ul a {
        white-space:normal;
    }
}