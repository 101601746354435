.strategy-call-page {
    .header {
        padding: 100px 90px;
        h1 {
            color: white;
            font-size: 32px;
            line-height: 60px;
            // text-align: left;
            margin: 0;
        }
    }

    .content {
        display: flex;
        justify-content: center;
        align-items: stretch;

        .inner-content {
            padding: 60px 35px 120px 35px;
            text-align: left;

            h4 {
                margin: 0;
                font-size: 30px;
                line-height: 48px;
                margin-bottom: 50px;
            }

            p {
                font-size: 24px;
                line-height: 36px;
                margin: 0;
            }

            h5 {
                font-size: 24px;
                line-height: 36px;
                font-weight: bold;
                font-family: "Roboto", sans-serif;
                margin: 0;
                margin-top: 50px;
            }
            ul {
                margin: 0;
                padding-inline-start: 10px;
                display: flex;
                justify-content: space-around;

                li {
                    font-size: 24px;
                    line-height: 36px;
                    margin-left: 20px;

                    &:first-child {
                        margin-top: 40px;
                    }

                    &:last-child {
                        margin-bottom: 40px;
                    }
                }
            }

            .end-button {
                margin-top: 40px;
                display: grid;
                place-content: center;
            }
        }

        img {
            display: block;
            width: 50%;
            object-fit: cover;
        }
    }
}

@media only screen and (max-width: 1350px) {
    .strategy-call-page .header {
        padding: 60px 40px;

        h1 {
            font-size: 28px;
            line-height: 48px;
        }
    }

    .strategy-call-page .content .inner-content {
        padding: 60px 30px 100px 30px;
    }
}

@media only screen and (max-width: 1350px) {

    .strategy-call-page .content .inner-content h4 {
        font-size: 28px;
    }
}

@media only screen and (max-width: 1250px) {

    .strategy-call-page .content .inner-content h4 {
        font-size: 25px;
    }
}

@media only screen and (max-width: 1115px) {
    .strategy-call-page .header {


        h1 {
            font-size: 24px;
            line-height: 48px;
        }
    }

    .strategy-call-page .content .inner-content h4 {
        font-size: 22px;
        line-height: 36px;
        margin-bottom: 35px;
    }

    .strategy-call-page .content .inner-content p {
        font-size: 18px;
        line-height: 28px;
        margin: 0;
        margin-bottom: 20px;
    }

    .strategy-call-page .content .inner-content h5 {
        font-size: 20px;
        line-height: 26px;
        margin-top: 35px;
    }

    .strategy-call-page .content .inner-content ul li:first-child {
        margin-top: 20px;   
    }

    .strategy-call-page .content .inner-content ul li {
        font-size: 18px;
        line-height: 24px;
    }
}

@media only screen and (max-width: 980px) {
    .strategy-call-page .content .inner-content ul 
    {
        margin: 20px 0;
        display: block;
        
        li {
            margin: 5px 0;
        }

        li:last-child{
            margin-bottom: 0px;
        }

        li:first-child{
            margin-top: 0;
        }
    }
    
    .strategy-call-page .header {


        h1 {
            font-size: 20px;
        }
    }
        .strategy-call-page .content .inner-content h4 {
            font-size: 21px;
        }

}

@media only screen and (max-width: 768px) {
    .strategy-call-page .header h1 br {
        display: none;
    }
    .strategy-call-page .content {
        flex-direction: column-reverse;
        & > img {
            width: 100%;
            object-position: center center;
        }
    }
}