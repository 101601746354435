.chris-page {
    .page-header {
        padding: 2rem 1rem;
        margin: 0 auto;
        background: #faefe7;
        text-align: left;

        .header-content {
            max-width: 1040px;
            margin: 0 auto;
        }
        h1 {
            margin-bottom: 0;

        }
        p {
            margin: 0;
            svg {
                margin-right: 10px;
            }
        }

        .profile-image {
            height: 0px;
            position: relative;

            .image-wrapper {
                position: absolute;
                width: fit-content;
                margin-left: auto;
                right: 0;
                top: -90px;

                & > img {
                    width: 408px;
                    height: 450px;
                    border-radius: 10px;
                    object-fit: cover;
                    object-position: center;
                }

                .contact-data {
                    background: white;
                    border-radius: 10px;
                    position: absolute;
                    right: 0;
                    bottom: -40px;
                    padding: 24px 32px;
                    max-width: 394px;
                    box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);

                    h6 {
                        margin-block-start: 1em;
                        margin-block-end: 1em;
                        font-size: 15px;
                        text-align: center;
                    }

                    ul, li {
                        list-style: none;
                        padding-inline-start: 0px;
                        margin: 0;
                    }
                    li {
                        font-size: 18px;
                        line-height: 45px;
                    }

                    li.phone {
                        color: #666666;
                    }

                    li.envelope {
                        color: #666666;
                    }

                    li.facebook {
                        color: #1877f2;
                    }

                    li a {
                        text-decoration: none;
                        color: #666666;
                        margin-left: 10px;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    .comments {
                        font-weight: 900;
                        font-size: 20px;
                        font-style: normal;
                        font-variant: normal;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 60px;
                        height: 60px;
                        box-shadow: 0px 10px 20px 5px rgb(0 0 0 / 10%) !important;
                        border-radius: 30px;
                        position: absolute;
                        top: -20px;
                        left: 20px;
                        background-color: #f7f3d9;
                        color: #d7c241;
                    }
                }
            }
        }
    }

    .page-content {
        min-height: 500px;
        max-width: 1040px;
        margin: 0 auto;
        margin-right: auto;
        text-align: left;
        padding: 2rem 0rem;

        .text {
            max-width: calc(100% - 408px);
            font-size: 18px;
            line-height: 1.5;
        }
    }

    .questions-container {
        background: #efefef;
        padding-bottom: 100px;
        padding-top: 20px;

        h1 {
            font-family: 'Roboto', sans-serif;
            font-size: 36px;
            margin: 1em 0px;
            font-weight: bold;
            text-align: center;
        }

        .qna-grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 30px;
            max-width: 1040px;
            margin: 0 auto;

            .qna-grid-column {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                & > div:last-child {
                    margin-bottom: 0px;
                }

                .questions-container {
                    border: 1px solid #e3e9ef;
                    background: white;
                    padding: 30px;
                    width: 100%;
                    margin-bottom: 30px;

                    .question-index {
                        color: #d15e14;
                        font-size: 36px;
                        font-weight: bold;
                    }

                    .question {
                        font-size: 18px;
                        margin: 1em 0;
                        font-weight: bold;
                        color: #222222;
                    }

                    .answer {
                        font-size: 15px;
                        margin: 1em 0;
                        color: #222222;
                    }
                }
            }
        }

        .qna-list {
            display: none;
            padding: 20px;
            .questions-container {
                border: 1px solid #e3e9ef;
                background: white;
                padding: 30px;
                width: 100%;
                margin-bottom: 30px;

                .question-index {
                    color: #d15e14;
                    font-size: 36px;
                    font-weight: bold;
                }

                .question {
                    font-size: 18px;
                    margin: 1em 0;
                    font-weight: bold;
                    color: #222222;
                }

                .answer {
                    font-size: 15px;
                    margin: 1em 0;
                    color: #222222;
                }
            }
        }
    }
}

@media only screen and (max-width: 1350px) {
    .chris-page .page-content {
        padding: 20px
    }

    .chris-page .questions-container .qna-grid {
        padding: 0px 20px;
    }

    .chris-page .page-header {
        padding: 2rem 20px;
    }
}

@media only screen and (max-width: 912px) {
    .chris-page .page-header .profile-image .image-wrapper > img {
        width: 330px;
        height: 390px;
    }

    .chris-page .page-header .profile-image .image-wrapper .contact-data {
        max-width: 290px;
        padding: 14px 22px;
    }

    .chris-page .page-header .profile-image .image-wrapper .contact-data li {
        font-size: 14px;
        line-height: 32px;
    }

    .chris-page .page-content .text {
        max-width: calc(100%  - 340px);
    }
}

@media only screen and (max-width: 768px) {
    .header-content {
        display: grid;
        grid-template-areas: "image-and-contact"
            "name"
            "title";

        h1 {
            grid-area: name;
        }

        p {
            grid-area: title;
        }

        .profile-image {
            grid-area: image-and-contact;
        }
    }

    .chris-page .page-content .text {
        max-width: 100%;
    }

    .chris-page {
        .page-header .profile-image {
            height: auto;
            .image-wrapper {
                position: static;
                margin: unset;
                width: 100%;
    
                & > img {
                    width: 100%;
                    object-position: top center;
                }
    
                .contact-data {
                    position: relative;
                    max-width: unset;
                    bottom: 0;
                }
            }
        }

        .questions-container {
            padding-bottom: 20px;
            .qna-grid {
                display: none;
            }
    
            .qna-list {
                display: block;
            }
        }
    }
}