.custom-button {
    color: white;
    background: #d86c1c;
    height: 50px;
    padding: 0px 35px;
    font-size: 20px;
    line-height: 42px;
    /* font-weight: 700; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    border: 0px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 1px;
}

@media only screen and (max-width: 1350px) {
    // .custom-button {
    //     height: 60px;
    //     font-size: 25px;
    //     line-height: 36px;
    //     padding: 0px 37px;
    // }
}

@media only screen and (max-width: 1300px) {
    .custom-button {
        padding: 0px 30px;
    }
}

@media only screen and (max-width: 1200px) {
    .custom-button {
        font-size: 20px;
        padding: 0px 20px;
    }
}

@media only screen and (max-width: 1200px) {
    .custom-button {
        padding: 0px 30px;
    }
}

@media only screen and (max-width: 912px) {
    .custom-button {
        font-size: 20px;
        line-height: 26px;
        padding: 0 10px;
    }
}

@media only screen and (max-width: 550px) {
    .custom-button {
        height: 43px;
    }
        
}