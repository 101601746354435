@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1400px;
  margin: auto;
  background: #efefef;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

#root {
  background: white;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Oswald', Arial, sans-serif;
}

p, span, div {
  font-family: 'Roboto', Arial, sans-serif;
}

// @media only screen and (max-width: 1700px) {
//   body {
//     max-width: 100%;
//   }
// }

// @media only screen and (max-width: 768px) {
//   body {
//     padding: 0;
//   }
// }